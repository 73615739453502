// TODO REFACTOR THIS ENTIRE FILE
import Rails from "@rails/ujs";
import axios from "axios";

document.addEventListener("turbolinks:load", () => {
  window.ODD_OS_PRED = [];
  const KEYUP_DEBOUNCE = 300;

  const sortContainers = document.querySelectorAll("[data-sort-container]");
  const dataFilters = document.querySelectorAll("[data-filter-field]");
  const orderWrappers = document.querySelectorAll("[data-sort-wrapper]");
  const clearAllFilters = document.querySelectorAll("[data-clear-all-filter]");

  const baseParams = new URLSearchParams(window.location.search);

  const accordionContainers = document.querySelectorAll(
    "[data-accordion-container]"
  );

  updateActiveTagRegions()

  accordionContainers.forEach((container) => {
    const trigger = container.querySelector("[data-accordion-trigger]");
    const content = container.querySelector("[data-accordion-content]");
    const openIcon = container.querySelector("[data-accordion-opener]");
    const closeIcon = container.querySelector("[data-accordion-closer]");

    const toggleIconDisplays = () => {
      if (closeIcon) {
        closeIcon.classList.toggle("block");
        closeIcon.classList.toggle("hidden");
      }
      if (openIcon) {
        openIcon.classList.toggle("hidden");
        openIcon.classList.toggle("block");
      }
    };
    trigger.addEventListener("click", () => {
      content.classList.toggle("mt-6");
      if (trigger.dataset.open == "true") {
        content.style.maxHeight = null;
        trigger.setAttribute("data-open", "false");
        toggleIconDisplays();
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
        trigger.setAttribute("data-open", "true");
        toggleIconDisplays();
      }
    });
  });

  sortContainers.forEach((container) => {
    const openers = container.querySelectorAll("[data-sort-opener]");
    const sortFields = container.querySelectorAll("[data-sort-field]");
    // Only going to expect one
    const options = container.querySelector("[data-sort-options]");
    const indicator = container.querySelector("[data-sort-state-indicator]");
    openers.forEach((opener) => {
      opener.addEventListener("click", (e) => {
        e.preventDefault();
        if (container !== document.activeElement) {
          container.focus();
          container.dispatchEvent(new CustomEvent("toggle-sort-options"));
        }
      });
    });

    container.addEventListener("blur", (e) => {
      const originalTarget = e.originalTarget;
      const relatedTarget = e.relatedTarget;
      if (originalTarget.contains(relatedTarget)) {
      } else {
        if (options.dataset.open == "true") {
          container.dispatchEvent(new CustomEvent("toggle-sort-options"));
        }
      }
    });
    container.addEventListener("toggle-sort-options", () => {
      if (options) {
        if (indicator) {
          indicator.classList.toggle("rotate-180");
        }
        if (options.dataset.open == "true") {
          options.classList.remove("opacity-100", 'scale-100"');
          options.classList.add("opacity-0", "scale-95");
          options.setAttribute("data-open", "false");
        } else {
          options.classList.remove("opacity-0", 'scale-95"');
          options.classList.add("opacity-100", "scale-100");
          options.setAttribute("data-open", "true");
        }
        options.classList.toggle("hidden");
      }
    });

    sortFields.forEach((field, index) => {
      const fieldLabel = field.closest("[data-sort-field-label]");
      const toggleFieldLabel = () => {
        if (fieldLabel) {
          fieldLabel.classList.toggle("text-gray-800");
          fieldLabel.classList.toggle("font-semibold");
        }
      };
      // TODO handle ordering issues 

      if (
        baseParams.has(field.name) &&
        field.value == baseParams.get(field.name)
      ) {
        
        if (baseParams.has('order') && field.dataset.order) {
          if (field.dataset.order == baseParams.get('order')) {
            toggleFieldLabel();
            field.setAttribute("data-is-active", "true");
          }
        } else {
          toggleFieldLabel();
          field.setAttribute("data-is-active", "true");
        }
      } else if (!baseParams.has(field.name) && index === 0) {
        toggleFieldLabel();
        field.setAttribute("data-is-active", "true");
      }
      field.addEventListener("click", (e) => {
        sortFields.forEach((sortField) => {
          if (sortField != e.target) {
            sortField.setAttribute("data-is-active", "false");
            const sortFieldLabel = sortField.closest("[data-sort-field-label]");
            if (sortFieldLabel) {
              sortFieldLabel.classList.remove("font-semibold", "text-gray-800");
            }
          }
        });
        if (field.dataset.isActive === "false") {
          field.setAttribute("data-is-active", "true");
          toggleFieldLabel();
          handleOptionalFormField(field, e.target);
        }
        container.focus();
      });
    });

    orderWrappers.forEach((wrapper) => {
      const fields = wrapper.querySelectorAll("[data-order-field]");
      fields.forEach((field) => {
        field.addEventListener("click", (e) => {
          fields.forEach((field) => {
            if (field != e.target) {
              const label = field.closest("[data-sort-label]");
              if (label) {
                label.setAttribute("for", field.id);
                const indicator = label.querySelector("[data-sort-indicator]");
                if (indicator) {
                  indicator.classList.toggle("rotate-180");
                }
              }
            }
          });
          handleOptionalFormField(field, e.target);
        });
      });
    });
  });

  dataFilters.forEach((filter) => {
    const filterInputs = filter.querySelectorAll(
      'input[data-filter-input]:not([type="submit"])'
    );
    const arrayValues = [];
    filterInputs.forEach((input) => {
      if (baseParams.has(input.name)) {
        if (input.name.includes("[]")) {
          // This is for checkbox values
          const values = baseParams.getAll(input.name);
          values.forEach((value) => {
            if (!arrayValues.includes(value)) {
              document
                .querySelectorAll(`[name="${input.name}"][value="${value}"]`)
                .forEach((element) => {
                  element.checked = true;
                  maybeUpdateFilterUI(element);
                });
              arrayValues.push(value);
            }
          });
        } else {
          input.value = baseParams.get(input.name);
        }
      }
      differentiallyApplyListeners(input, filter);
    });
  });

  clearAllFilters.forEach((clearAll) => {
    clearAll.addEventListener("click", () => {
      // TODO finish implementation of hide all
        let form = null
        const filters = document.querySelectorAll("[data-active-filter-container]")
        filters.forEach((filterContainer) => {
          const labels = filterContainer.querySelectorAll("label");
          let name = "";
          labels.forEach((label) => {
            document
              .querySelectorAll(`[value="${label.dataset.textValue}"]`)
              .forEach((element) => {
                element.checked = false;
                if (!name) {
                  name = element.name;
                }
              });
            label.remove();
          });
          if (!form) {
            form = filterContainer.closest("[data-filter-field]");
          }
          
        })
        if (form) {
          updateQueryParams(new URLSearchParams(new FormData(form)), name);
          Rails.fire(form, "submit");
        }
        updateActiveFilterRegions()
        updateActiveTagRegions()
      }
    );
  });

  function updateActiveFilterRegions() {
    const filterRegions = document.querySelectorAll('[data-active-filter-region]')
    filterRegions.forEach((region) => {
      const tagWrapper = region.querySelector('[data-active-filter-container]') 
      if (tagWrapper) {
        if (tagWrapper.childElementCount <= 0) {
          if (!region.classList.contains("lg:hidden")) {
            region.classList.add("lg:hidden");
          }
        }
      }
    })
  }

  function updateActiveTagRegions() {
    let activeTags = 0
    const totalCounter = document.querySelector('[data-total-filter-count]')
    if (totalCounter) {
      const filterRegions = document.querySelectorAll('[data-active-filter-region]')
      filterRegions.forEach((region) => {
        const tagWrapper = region.querySelector('[data-active-filter-container]') 
        if (tagWrapper) {
          activeTags += tagWrapper.childElementCount
        }
      })
    
      if (activeTags > 1) {
        totalCounter.innerHTML =  `${activeTags} ${totalCounter.dataset.indicatorPlural}`
        if (!totalCounter.classList.contains('mb-[48px]')) {
          totalCounter.classList.add('mb-[48px]')
        }
      } else if (activeTags > 0) {
        totalCounter.innerHTML =  `${activeTags} ${totalCounter.dataset.indicatorSingluar}`
        if (!totalCounter.classList.contains('mb-[48px]')) {
          totalCounter.classList.add('mb-[48px]')
        }
      } else {
        totalCounter.innerHTML = null
        if (totalCounter.classList.contains('mb-[48px]')) {
          totalCounter.classList.remove('mb-[48px]')
        }
      }
      
    }
    clearAllFilters.forEach((filterTrigger) => {
      if (activeTags > 0) {
        if (filterTrigger.classList.contains("lg:hidden")) {
          filterTrigger.classList.remove("lg:hidden");
        }
      } else {
        if (!filterTrigger.classList.contains("lg:hidden")) {
          filterTrigger.classList.add("lg:hidden");
        }
      }
    })

  }

  function handleOptionalFormField(field, target) {
    const form = field.closest("[data-filter-field]");
    if (form) {
      const formOrderWrapper = form.querySelector('[data-order-wrapper]')
      if (target.dataset.order) {
        if (formOrderWrapper) {
          formOrderWrapper.value = target.dataset.order
        } else {
          const newWrapper = document.createElement('input')
          newWrapper.type = 'text'
          newWrapper.name = 'order'
          newWrapper.classList.add('sr-only')
          newWrapper.setAttribute('data-order-wrapper', '')
          newWrapper.value = target.dataset.order
          form.appendChild(newWrapper)
        }
      } else if (formOrderWrapper) {
        formOrderWrapper.remove()
      }
      updateQueryParams(new URLSearchParams(new FormData(form)), target.name);
      Rails.fire(form, "submit");
    } else {
      const formData = new FormData();
      formData.append(target.name, target.value);
      if (target.dataset.order) {
        formData.append('order',target.dataset.order )
      }
      updateQueryParams(new URLSearchParams(formData), target.name);
      Rails.fire(field, "submit");
    }
  }

  function differentiallyApplyListeners(input, formElement) {
    const eventFunction = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      maybeUpdateFilterUI(input);

      updateMatchingElements(name, value, e.target);
      updateQueryParams(new URLSearchParams(new FormData(formElement)), name);
      Rails.fire(formElement, "submit");
    };
    let event = "change";
    let appliedFunction = eventFunction;
    switch (input.type) {
      case "text":
        event = "keyup";
        appliedFunction = debounce(eventFunction, KEYUP_DEBOUNCE);
        break;
      default:
        break;
    }
    input.addEventListener(event, appliedFunction);
  }

  function maybeUpdateFilterUI(input) {
    const inputContainer = input.closest("[data-input-container]");
    const activeContainer = document.querySelector(
      `[data-active-filters-${input.dataset.field}]`
    );
    if (activeContainer && inputContainer) {
      const filterContainer = activeContainer.querySelector(
        "[data-active-filter-container]"
      );

      if (filterContainer ) {
        if (input.checked) {
          if (
            !activeContainer.querySelector(`[data-text-value="${input.value}"]`)
          ) {
            createActiveFilter(
              input,
              inputContainer.querySelector("[data-uploads-wrapper]"),
              activeContainer,
              filterContainer
            );
          }
        } else {
          const matchingFilter = filterContainer.querySelector(
            `[data-text-value="${input.value}"]`
          );
          if (matchingFilter) matchingFilter.remove();
        }
        updateActiveTagRegions()
        maybeHideContainer(activeContainer, filterContainer);
      }
    }
  }

  function createActiveFilter(
    input,
    modal,
    parentContainer,
    container,
    counter
  ) {
    // input.clone
    const newFilter = document.createElement("label");
    newFilter.innerHTML = input.value;
    newFilter.dataset.textValue = input.value;
    newFilter.dataset.activeFilter = "";
    const filterClasses =
      "relative pr-10 transition duration-200 select-none inline-flex bg-oddit-blue text-white items-center px-4 py-2 hover:text-oddit-blue hover:bg-oddit-light-blue-2 rounded font-medium cursor-pointer";
    newFilter.classList.add(...filterClasses.split(" "));

    if (modal) {
      const modalCopy = modal.cloneNode(true);
      const modalButton = modalCopy.querySelector(
        "[data-uploads-modal-trigger]"
      );
      modalCopy.className = "";
      modalButton.classList.add("border-2", "border-oddit-blue");
      newFilter.appendChild(modalCopy);
    } else {
      newFilter.classList.toggle("pr-10");
    }
    newFilter.addEventListener("click", function () {
      input.click();
      this.remove();

      // updateFilterCounter(counter, container.childElementCount);
      updateActiveTagRegions()
      maybeHideContainer(parentContainer, container);
    });

    container.appendChild(newFilter);
  }

  function maybeHideContainer(parentContainer, filterContainer) {
    if (filterContainer.childElementCount) {
      if (parentContainer.classList.contains("lg:hidden")) {
        parentContainer.classList.remove("lg:hidden");
      }
    } else {
      if (!parentContainer.classList.contains("lg:hidden")) {
        parentContainer.classList.add("lg:hidden");
      }
    }
  }

  function updateFilterCounter(counter, number) {
    let counterText = counter.dataset.filterMeasurement || "Filters";
    if (number) {
      const lastChar = counterText.split("").pop().toUpperCase();
      if (number === 1 && lastChar === "S") {
        counterText = counterText.slice(0, counterText.length - 1);
      }
      counter.innerHTML = `${number} ${counterText}`;
    } else {
      counter.innerHTML = "";
    }
  }

  function updateMatchingElements(name, value, target) {
    const matchingElements = document.querySelectorAll(`[name="${name}"]`);
    matchingElements.forEach((element) => {
      if (element !== target) {
        const type = target.type;
        switch (type) {
          case "checkbox":
            if (element.value === value) element.checked = target.checked;
            break;
          default:
            element.value = value;
        }
      }
    });
  }

  function updateQueryParams(params, fieldValue) {
    const pathName = window.location.pathname;
    const windowSearchParams = new URLSearchParams(window.location.search);
    windowSearchParams.forEach((value, key) => {
      if (params.has(key)) {
        while (windowSearchParams.has(key)) windowSearchParams.delete(key);
      }
    });
    let fieldPresent = false;
    params.forEach(function (value, key) {
      if (key === fieldValue && value) fieldPresent = true;
      const existingValues = windowSearchParams.getAll(key);
      if (
        value &&
        (existingValues.length === 0 || !existingValues.includes(value))
      ) {
        windowSearchParams.append(key, value);
      }
    });
    if (!fieldPresent) {
      windowSearchParams.delete(fieldValue);
    }
    const paramString = windowSearchParams.toString();
    const newPath = paramString ? [pathName, paramString].join("?") : pathName;
    history.replaceState(history.state, "", newPath);
  }

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
});

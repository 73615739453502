import Rails from "@rails/ujs";
import axios from "axios";

// import Choices from 'choices.js';

document.addEventListener("turbolinks:load", setupFormActions);
document.addEventListener("partialReRendered", setupFormActions);

function setupFormActions() {
  const LOADER_ACTIVE_CLASS = 'td--loader-visible'
  const PREDICTIVE_ACTIVE_CLASS = 'border-2'
  const MATCHED_OPTION_CLASS = 'bg-oddit-light-blue'
    
  const TRANSITION_DURATION = 300;
  const dangerousMethods = document.querySelectorAll(
    "[data-confirm-dangerous]"
  );
  const tagCloudFields = document.querySelectorAll(
    "[data-tag-cloud-container]"
  );

  const fileUploadFields = document.querySelectorAll("[data-file-field]");
  const dragAndDropField = document.querySelectorAll(
    "[data-drag-and-drop-file]"
  );

  const uploadRemovalTriggers = document.querySelectorAll(
    "[data-remove-upload]"
  );

  // const predictiveSearchBox = document.querySelectorAll("[data-predictive-search-box]")
  const predictiveTextEntry = document.querySelectorAll("[data-predictive-text-entry]")

  const alreadyAvailableTags = document.querySelectorAll("[data-existing-tag-option]")

  dangerousMethods.forEach((dangerousMethod) => {
    const methodForm = dangerousMethod.closest("form");
    const warning = methodForm.querySelector("[data-dangerous-method-modal]");
    const overlay = warning.querySelector("[data-modal-overlay]");
    const modal = warning.querySelector("[data-modal]");
    const cancel = warning.querySelector("[data-cancel]");
    const confirm = warning.querySelector("[data-confirm]");

    const closeWarning = () => {
      overlay.classList.remove("opacity-100");
      overlay.classList.add("opacity-0");
      modal.classList.add(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
      setTimeout(() => {
        warning.classList.remove("z-40");
        warning.classList.add("-z-10");
      }, TRANSITION_DURATION);
    };

    dangerousMethod.addEventListener("click", (e) => {
      e.preventDefault();
      warning.classList.remove("-z-10");
      warning.classList.add("z-40");
      overlay.classList.remove("opacity-0");
      overlay.classList.add("opacity-100");
      modal.classList.remove(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.add("opacity-100", "translate-y-0", "sm:scale-100");
    });
    overlay.addEventListener("click", () => {
      closeWarning();
    });
    cancel.addEventListener("click", () => {
      closeWarning();
    });
    confirm.addEventListener("click", () => {
      // console.log(methodForm);
      // console.lo;
      // Rails.fire(methodForm, "submit");
      methodForm.submit();
    });
  });

  tagCloudFields.forEach((tagCloud) => {
    const toggle = tagCloud.querySelector("[data-cloud-toggle]");
    const cloudContainers = tagCloud.querySelectorAll("[data-tag-cloud]");

    if (toggle) {
      toggle.addEventListener("click", (e) => {
        const toggleButton = toggle.querySelector("button");
        const toggleSpan = toggle.querySelector("span");
        if (toggle.dataset.closed === "true") {
          replaceClass(toggleButton, "bg-gray-200", "bg-zinc-600");
          replaceClass(toggleSpan, "translate-x-0", "translate-x-5");
          cloudContainers.forEach((container) => {
            resizeExpandingSection(container, true);
          });
          toggle.setAttribute("data-closed", "false");
        } else {
          replaceClass(toggleButton, "bg-zinc-600", "bg-gray-200");
          replaceClass(toggleSpan, "translate-x-5", "translate-x-0");
          cloudContainers.forEach((container) => {
            resizeExpandingSection(container, false);
          });
          toggle.setAttribute("data-closed", "true");
        }
      });

      window.addEventListener("resize", () => {
        cloudContainers.forEach((container) => {
          if (toggle.dataset.closed !== "true") {
            resizeExpandingSection(container, true);
          }
        });
      });
    }

    cloudContainers.forEach((container) => {
      const tagContainer = container.querySelector("[data-tag-container]");
      const clearButton = container.querySelector("[data-clear-all]");
      const addTagField = container.querySelector("[data-tag-to-add]");
      const addTagButton = container.querySelector("[data-add-tag]");
      const inputs = container.querySelectorAll("input[type='checkbox']");

      const checkedElements = container.querySelectorAll("input:checked");
      if (checkedElements?.length > 0 && toggle) {
        if (toggle.getAttribute("data-closed") == "true") {
          toggle.dispatchEvent(new Event("click"));
        }
      }
      // inputs.forEach((input) => {
      //   input.addEventListener("change", (e) => {
      //     const label = input.closest("label");
      //     if (label) label.remove();
      //   });
      // });
      clearButton.addEventListener("click", () => {
        container.querySelectorAll("input[type='checkbox']").forEach((input) => {
          input.checked = false;
        });
      });
      // addTagField.addEventListener("keyup", (e) => {
      //   debounce(maybeApplyPredictiveSearch(addTagField, e.target.value), 300);
      // });
      addTagButton.addEventListener("click", () => {
        if (addTagField.value) {
          const newTag = createTag(
            addTagField.value.toUpperCase(),
            addTagButton.dataset.field
          );
          tagContainer.appendChild(newTag);
          addTagField.value = "";
          resizeExpandingSection(container, true);
        }
      });
    });
  });

  function maybeApplyPredictiveSearch(input, value) {
    if (input.dataset.predictive) {
      // input.addEventListener("blur", function () {
      //   clearSearchResults(input);
      // });
      if (value) {
        axios.get(`${input.dataset.predictive}?q=${value}`).then(({ data }) => {
          const container = input.closest("[data-container]");
          if (data.suggestions !== window.ODD_OS_PRED) {
            window.window.ODD_OS_PRED = data.suggestions;
            clearSearchResults(input);
            let results = createEmptySearchElement();
            if (data.suggestions.length) {
              results = createSearchResults(input, data.suggestions);
            }
            container.appendChild(results);
            results.focus();
            results.addEventListener("blur", function () {
              clearSearchResults(input);
            });
          }
        });
      } else {
        clearSearchResults(input);
      }
    }
  }

  function createSearchResults(input, results) {
    const wrapperClasses =
      "search-children absolute left-0 border-2 border-t-0 border-oddit-blue -mt-1 bg-gray-50 z-10 w-full transition-all duration-300 overflow-hidden";
    const wrapper = document.createElement("div");
    wrapper.classList.add(...wrapperClasses.split(" "));
    results.forEach((result) => {
      const resultClasses = "pl-10 text-oddit-blue cursor-pointer py-2";
      const resultDiv = document.createElement("div");
      resultDiv.innerText = result;
      resultDiv.classList.add(...resultClasses.split(" "));
      resultDiv.addEventListener("click", function () {
        input.value = this.innerText;
      });
      wrapper.appendChild(resultDiv);
    });
    return wrapper;
  }
  function clearSearchResults(input) {
    const container = input.closest("[data-container]");
    const searchResults = container.querySelectorAll(".search-children");
    if (searchResults) {
      [...searchResults].forEach((result) => {
        result.remove();
      });
    }
  }

  function createEmptySearchElement() {
    const emptyClasses =
      "search-children absolute left-0 pl-10 flex items-center pointer-events-none border-2 border-t-0 border-oddit-blue -mt-1 bg-gray-50 text-gray-600 z-10 w-full py-2 transition-all duration-300 overflow-hidden";
    const div = document.createElement("div");
    div.classList.add(...emptyClasses.split(" "));
    div.innerText = "No Matches";
    return div;
  }

  function resizeExpandingSection(expander, open) {
    if (open) {
      expander.style.maxHeight = expander.scrollHeight + "px";
    } else {
      expander.style.maxHeight = 0;
    }
  }

  function replaceClass(element, toRemove, toAdd) {
    element.classList.remove(toRemove);
    element.classList.add(toAdd);
  }

  function createTag(tagName, fieldName) {
    const cleanedTag = tagName.replaceAll(/\s/gm, "-").toLowerCase();
    const labelClasses = "block";
    const label = document.createElement("label");
    label.classList.add(...labelClasses.split(" "));
    label.for = cleanedTag;

    const inputClasses = "sr-only peer";
    const input = document.createElement("input");
    input.classList.add(...inputClasses.split(" "));
    input.name = fieldName + "[]";
    input.type = "checkbox";
    input.value = tagName;
    input.id = cleanedTag;
    input.checked = true;
    // input.addEventListener("change", (e) => {
    //   const label = input.closest("label");
    //   if (label) label.remove();
    // });
    const spanClasses =
      "transition duration-200 select-none bg-oddit-light-blue text-oddit-blue hover:text-oddit-light-blue-2 inline-flex peer-checked:bg-oddit-blue peer-checked:text-white items-center px-4 py-2 peer-checked:hover:bg-oddit-light-blue-2 peer-checked:hover:text-oddit-blue rounded font-medium cursor-pointer";
    const span = document.createElement("span");
    span.classList.add(...spanClasses.split(" "));
    span.innerHTML = tagName;

    // const remover = document.createElement("span");
    // remover.innerHTML = `
    //   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 rotate-45">
    //     <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    //   </svg>
    // `;
    // span.appendChild(remover);

    label.appendChild(input);
    label.appendChild(span);

    return label;
  }

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  fileUploadFields.forEach((uploadField) => {
    if (uploadField.files.length > 0) {
      handleFileField(uploadField);
      uploadField.setAttribute('data-files', uploadField.files)
    }
    uploadField.addEventListener("change", (e) => {
      if (e.target.files.length > 0) {
        handleFileField(e.target);
      }
    });
  });

  dragAndDropField.forEach((dAndDField) => {
    const fileField = dAndDField.querySelector("[data-file-field]");
    const errorContainer = dAndDField.querySelector(
      "[data-file-upload-error-container]"
    );
    const errorMessages = errorContainer.querySelector(
      "[data-file-upload-error-messages]"
    );
    dAndDField.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    dAndDField.addEventListener("drop", (e) => {
      e.preventDefault();
      const validTransfer = new DataTransfer();
      const uploadErrors = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (fileField.accept.includes(file.type)) {
          validTransfer.items.add(file);
        } else {
          uploadErrors.push({
            file: file.name,
            error: `Unsupported file type`,
          });
        }
      }
      if (validTransfer.files.length > 0) {
        fileField.files = validTransfer.files;
        fileField.dispatchEvent(new Event("change"));
      }
      if (uploadErrors.length > 0) {
        errorContainer.classList.toggle("hidden");
        errorMessages.innerHTML = uploadErrors.map(
          (error) => `${error.file} - ${error.error}`
        );
      } else {
        if (!errorContainer.classList.contains("hidden")) {
          errorContainer.classList.toggle("hidden");
        }
        errorMessages.innerHTML = "";
      }
    });
  });

  function handleFileField(uploadElement) {
    const uploadWrapper = uploadElement.closest("[data-file-upload-modal]");
    const fileTemplate = uploadWrapper.querySelector("#file-upload-template");
    const fileContainer = uploadWrapper.querySelector("[data-file-container]");
    if (fileTemplate && fileContainer) {
      // NOTE Default behaviour clears all file if using the input, going to clear it out to prevent issues
      fileContainer.innerHTML = null;
      [...uploadElement.files].forEach((file, index) => {
        const templateNode = fileTemplate.content.cloneNode(true);
        const image = templateNode.querySelector("img");
        image.src = URL.createObjectURL(file);
        const button = templateNode.querySelector("[data-remove-upload]");
        button.setAttribute("data-uploaded-file", file.name);
        button.addEventListener("click", function () {
          const dt = new DataTransfer();
          const { files } = uploadElement;
          for (let i = 0; i < files.length; i++) {
            const cFile = files[i];
            if (cFile.name !== file.name) dt.items.add(cFile); // here you exclude the file. thus removing it.
          }
          uploadElement.files = dt.files; // Assign the updates list
          removeFormUpload(button);
        });
        fileContainer.appendChild(templateNode);
      });
    }
  }
  uploadRemovalTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => removeFormUpload(trigger));
  });
  function removeFormUpload(trigger) {
    const wrapper = trigger.closest("[data-upload-wrapper]");
    const container = trigger.closest('[data-file-container]')
    if (wrapper) {
      wrapper.remove();
    }
    if (container && !container.childElementCount) {
      container.innerHTML = null
    }
  }


  // predictiveSearchBox.forEach((multiselect) => {
  //   generatePredictiveSearchBox(multiselect)
  // })

  // function generatePredictiveSearchBox(multiselect) {
  //   const choiceOptions =  {
  //     addItems: true,
  //     removeItemButton: true,
  //     duplicateItemsAllowed: false,
  //     allowHTML: true,
  //     classNames: {
  //       containerOuter: 'choices b',
  //       containerInner: 'choices__inner min-h-[unset!important] h-full py-[0!important] pt-[3px!important] mb-[0!important] bg-[white!important] focus:ring-0 block w-full sm:text-sm border-[#2B161B!important] focus:border-td-primary rounded-[0.125rem!important] cursor-pointer',
  //       input: 'choices__input',
  //       inputCloned: 'choices__input--cloned bg-[white!important] focus:ring-0 mb-[0!important] p-1',
  //     },
  //   }
  //   if (multiselect.dataset.items && !multiselect.value) {
  //     choiceOptions.items = multiselect.dataset.items.split(',')
  //   }
  //   const choice = new Choices(multiselect, choiceOptions);
  // }

  // TODO add gem for caching API requests
  predictiveTextEntry.forEach((inputField) => {
    
    inputField.addEventListener("keyup", debounce(maybeApplyPredictiveSearchTextEntry.bind(this), 1000))
    // document.addEventListener('click', (e) => {
    //   console.log(predictiveContainer)
    //   console.log(e.target)
    //   if (!predictiveContainer.contains(e.target)) {
    //     resultsContainer.querySelectorAll('[data-result-elm]').forEach((elm) => elm.remove())
    //     if (resultsContainer.classList.contains(PREDICTIVE_ACTIVE_CLASS)) {
    //       resultsContainer.classList.remove(PREDICTIVE_ACTIVE_CLASS)
    //     }
    //   }
    // })
  })
  
  function maybeApplyPredictiveSearchTextEntry(event) {
    
    const target = event.target
    const endpoint = `${target.dataset.predictiveEndpoint}?q=${target.value}&rec_type=${target.dataset.predictiveRecordType}&rec_field=${target.dataset.predictiveField}&authenticity_token=${target.dataset.authToken}`
    const predictiveContainer = target.closest('[data-predictive-container]')
    if (predictiveContainer) {
      // const loader = predictiveContainer.querySelector('[data-predictive-loader]')
      const resultsContainer = predictiveContainer.querySelector('[data-predictive-results]')
      
      // if (loader && !loader.classList.contains(LOADER_ACTIVE_CLASS)) {
      //   loader.classList.add(LOADER_ACTIVE_CLASS)
      // } 
      handleClickOutside(predictiveContainer, () => {
        if (resultsContainer) {
          resultsContainer.querySelectorAll('[data-result-elm]').forEach((elm) => elm.remove())
          if (resultsContainer.classList.contains(PREDICTIVE_ACTIVE_CLASS)) {
            resultsContainer.classList.remove(PREDICTIVE_ACTIVE_CLASS)
          }
        }
      })
      axios.get(endpoint).then(({ data }) => {
        if (resultsContainer) {
          resultsContainer.querySelectorAll('[data-result-elm]').forEach((elm) => elm.remove())
          if (data.suggestions.length && !resultsContainer.classList.contains(PREDICTIVE_ACTIVE_CLASS)) {
            resultsContainer.classList.add(PREDICTIVE_ACTIVE_CLASS)
          } else if (!data.suggestions.length && resultsContainer.classList.contains(PREDICTIVE_ACTIVE_CLASS)) {
            resultsContainer.classList.remove(PREDICTIVE_ACTIVE_CLASS)
          }
          data.suggestions.forEach((suggestion) => {
            const newResult = createNewPredictiveResult(predictiveContainer, suggestion, target.dataset.predictiveRecordType, target.dataset.predictiveField)
            if (newResult) {
              resultsContainer.appendChild(newResult)
            }
          })
          // if (loader && loader.classList.contains(LOADER_ACTIVE_CLASS)) {
          //   loader.classList.remove(LOADER_ACTIVE_CLASS)
          // } 
        }
      }).catch((err) => {
        console.log(err)
        // TODO surface an error message
      }).finally(() => {
        // if (loader && loader.classList.contains(LOADER_ACTIVE_CLASS)) {
        //   loader.classList.remove(LOADER_ACTIVE_CLASS)
        // } 
      })
    }
  }

  

  function createNewPredictiveResult(wrapper, result, record, field) {
    const resultTemplate    = wrapper.querySelector('template[data-result]')
    if (resultTemplate) {
      const newResult = resultTemplate.content.cloneNode(true).firstElementChild;
      const matchingResultSelector =  `[data-existing-tag-model="${record}"][data-existing-tag-field="${field}"][data-existing-tag-option="${result}"]`
      newResult.innerHTML = result
      newResult.setAttribute('data-suggested-option', result)
      newResult.addEventListener('click', () => {
        const match = document.querySelector(matchingResultSelector)
        if (match) {
          match.checked = true
          newResult.classList.add(MATCHED_OPTION_CLASS)
        }
      })
      const alreadySelected = document.querySelector(`${matchingResultSelector}:checked`)
      if (alreadySelected && !newResult.classList.contains(MATCHED_OPTION_CLASS)) {
        newResult.classList.add(MATCHED_OPTION_CLASS)
      }
      return newResult
    }
    return false
  }

  function handleClickOutside(targetContainer, callback = undefined) {
    document.addEventListener('click', (e) => {
      if (!targetContainer.contains(e.target)) {
        if (callback) {
          callback()
        }
      }
    })
  }
}

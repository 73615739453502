import Macy from "macy";
import Swiper, { Pagination } from "swiper";
import axios from "axios";
import "fslightbox";

document.addEventListener("turbolinks:load", setupMiscActions);
document.addEventListener("partialReRendered", setupMiscActions);

function setupMiscActions() {
  const TRANSITION_DURATION = 300;

  const copyableContainers = document.querySelectorAll("[data-copy-container]");
  const masonryContainers = document.querySelectorAll("[data-masonry]");
  const uploadsTriggers = document.querySelectorAll(
    "[data-uploads-modal-trigger]"
  );

  const lightboxTriggers = document.querySelectorAll('[data-zoom-trigger]')

  const sliderWrappers = document.querySelectorAll("[data-slider-wrapper]");

  const backButtons = document.querySelectorAll("[data-back-button]")

  copyableContainers.forEach((element) => {
    element.addEventListener("click", (e) => {
      if (e.target.nodeName !== 'A') {
        if (e.target !== element && !e.target.classList.contains("")) {
          if (!e.target.closest("svg") && !e.target.closest("form")) {
            element.addEventListener(
              "transitionend",
              () => {
                element.classList.toggle("scale-99");
                element.addEventListener(
                  "transitionend",
                  () => {
                    element.classList.toggle("scale-101");
                  },
                  {
                    once: true,
                  }
                );
                element.classList.toggle("scale-101");
              },
              {
                once: true,
              }
            );
            element.classList.toggle("scale-99");
          }
        }
  
        const copyContent = element.querySelector("[data-copy-content]");
        if (copyContent && navigator?.clipboard) {
          navigator.clipboard.writeText(copyContent.innerText).then(() => {
            if (copyContent.dataset.hasOwnProperty('triggerUpdate')) {
              // TODO DEBOUNCE
              axios.post(`/api/v1/records?rec_type=${copyContent.dataset.recType}&rec_id=${copyContent.dataset.recId}&authenticity_token=${copyContent.dataset.authToken}`).then((resp) => {})
            }
          });
          
        }
      }
      
    });
  });

  masonryContainers.forEach((container) => {
    let settings = {
      columns: 1,
      margin: 20,
      mobileFirst: true,
      breakAt: {
        768: {
          columns: 2
        },
        1024: {
          columns: 3
        },
        1280: {
          columns: 4
        }
      }
    }
    if (container.dataset.masonryOptions) {
      try {
        console.log(container.dataset.masonryOptions)
        settings = JSON.parse(container.dataset.masonryOptions);
      } catch (err) {
        console.log('JSON Data Error in Masonry', container.dataset.masonryOptions);
      }
    }
   
    settings.container = container
    const masonryObject = new Macy(settings);
    setTimeout(() => {
      masonryObject.reInit()
    })

  });

  uploadsTriggers.forEach((trigger) => {
    const wrapper = trigger.closest("[data-uploads-wrapper]");
    const modalWrapper = wrapper.querySelector("[data-uploads-modal]");
    const overlay = modalWrapper.querySelector("[data-modal-overlay]");
    const modal = modalWrapper.querySelector("[data-modal]");
    const modalContainer = modalWrapper.querySelector("[data-modal-container]");
    const modalCloser = modalWrapper.querySelector("[data-modal-closer]");
    const closeModal = () => {
      overlay.classList.remove("opacity-100");
      overlay.classList.add("opacity-0");
      modal.classList.add(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
      setTimeout(() => {
        modalWrapper.classList.remove("z-40");
        modalWrapper.classList.add("-z-10");
      }, TRANSITION_DURATION);
    };

    trigger.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      modalWrapper.classList.remove("-z-10");
      modalWrapper.classList.add("z-40");
      overlay.classList.remove("opacity-0");
      overlay.classList.add("opacity-100");
      modal.classList.remove(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.add("opacity-100", "translate-y-0", "sm:scale-100");
    });
    modal.addEventListener("click", (e) => {
      e.stopPropagation();
    });
    modalContainer.addEventListener("click", (e) => {
      e.stopPropagation();
    });
    overlay.addEventListener("click", (e) => {
      e.stopPropagation();
      closeModal();
    });
    modalCloser.addEventListener("click", (e) => {
      e.stopPropagation();
      closeModal();
    });
  });

  sliderWrappers.forEach((wrapper) => {
    const swiperWrapper = wrapper.querySelector("[data-swiper-wrapper]");
    const nextButtons = wrapper.querySelectorAll("[data-next-slide]");
    const prevButtons = wrapper.querySelectorAll("[data-prev-slide]");
    const swiper = new Swiper(swiperWrapper, {
      modules: [Pagination],
      speed: 400,
      spaceBetween: 100,
      effect: "cube",
      loop: true
    });
    if (nextButtons.length && prevButtons.length) {
      prevButtons.forEach((button) => {
        button.addEventListener("click", () => swiper.slidePrev());
      });
      nextButtons.forEach((button) => {
        button.addEventListener("click", () => swiper.slideNext());
      });
    }
  });

  backButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault()
      window.history.back()
    })
  })

  lightboxTriggers.forEach((trigger) => {
    const modal = trigger.closest('[data-modal]')
    if (modal) {
      modal.querySelectorAll('[data-fslightbox]').forEach((elm) => {
        elm.addEventListener('click', (e) => {
          e.preventDefault()
        })
      })
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
          const activeSlide = modal.querySelector('.swiper-slide-active')
          if (activeSlide) {
            const lightbox = activeSlide.querySelector('[data-fslightbox]')
            if (lightbox) {
              const tempLightbox = new FsLightbox();
              tempLightbox.props.sources = [lightbox.href]
              tempLightbox.props.types = ["image"];
              tempLightbox.open()
            }
          }
      })
    }
  })

}
